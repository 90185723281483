import axios from "axios"
import {MessageBox,loading, Loading} from "element-ui"
import router from "../router/index.js"
import apiToken from '../common/token.js'

const requests = axios.create({
    baseURL: "/api",
    timeout: 300000
	
})

let loadingInstance= null 


//请求拦截器
requests.interceptors.request.use(config => {
	if(router.currentRoute.path != '/login'){
		const token = localStorage.getItem("token")
		if(token && token != "undefined"){
			const url = config.url
			const urlarr = url.split('/')
		
			const requestToken = apiToken(urlarr[1])
		
			config.headers.token = requestToken
		}else{
			MessageBox .alert("Token无效，请重新登录",{
				title:"Token失效",
				confirmButtonText:"返回登录",
				callback: action => {
				    window.location.href = "/login"
				}
			})
		}
	}
	if(!config.headers.noLoad){
		loadingInstance = Loading.service({
		    text: "加载中", // 设置 loading 文本为 "加载中"
		    spinner: "el-icon-loading", // 使用 Element UI 提供的加载图标
		    background: "rgba(0, 0, 0, 0.7)", // 设置 loading 遮罩层背景色为半透明黑色
		    target: document.querySelector("body"), // 将 loading 遮罩层挂载到页面 body 元素上
		});
	}

	return config
}, err => {
    return Promise.reject(err)
})

requests.interceptors.response.use(res => {
	loadingInstance.close();
	if(res.data.code == 40004){
		
		MessageBox .alert("非法访问，请重新登录",{
			title:"非法访问",
			confirmButtonText:"返回登录",
			callback: action => {
			    window.location.href = "/login"
			}
		})
		return false
	}else{
		return JSON.parse(res.data)
	}
    
}, err => {
    return Promise.reject(err)
})

export default requests