// router.js
import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@/views/layout/IndexView.vue';
import Login from '@/views/login/IndexView.vue';
import Home from '@/views/Home/IndexView.vue';
import Network from '@/views/Network/IndexView.vue';
//网点管理
const NetworkList = () => import("@/views/Network/NetworkList/IndexView.vue")
const NetworkDetails = () => import("@/views/Network/NetworkList/DetailsView.vue")
const NetworkAdd = () => import("@/views/Network/NetworkList/AddView.vue")
const NetworkEdit = () => import("@/views/Network/NetworkList/EditView.vue")
const SetPay = () => import("@/views/Network/NetworkList/SetPayView.vue")
const GetPay = () => import("@/views/Network/NetworkList/GetPayView.vue")
//兑换卡管理
const ExChange = () => import("@/views/Network/ExChange/IndexView.vue")
const ExChangeList = () => import("@/views/Network/ExChange/RecordView.vue")

//设备管理
const DeviceList = () => import("@/views/Network/DeviceList/IndexView.vue")
const DeviceDetails = () => import("@/views/Network/DeviceList/DetailsView.vue")
const DeviceAdd = () => import("@/views/Network/DeviceList/AddView.vue")
const DeviceEdit = () => import("@/views/Network/DeviceList/EditView.vue")
const DeviceSetPay = () => import("@/views/Network/DeviceList/SetPayView.vue")
//套餐卡管理
const Card = () => import("@/views/Network/CardList/IndexView.vue")
const CardAdd = () => import("@/views/Network/CardList/AddCardView.vue")
const HxAdd = () => import("@/views/Network/CardList/AddHxView.vue")
const CardEdit = () => import("@/views/Network/CardList/EditView.vue")
const HxCardEdit = () => import("@/views/Network/CardList/HxEditView.vue")
//优惠券管理
const Coupon = () => import("@/views/Network/CouponList/IndexView.vue")
const CouponAdd = () => import("@/views/Network/CouponList/AddView.vue")
const CouponEdit = () => import("@/views/Network/CouponList/EditView.vue")
const NetworkOrder = () => import("@/views/Network/OrderList/IndexView.vue")
const NetworkOrderDetails = () => import("@/views/Network/OrderList/OrderDetailsView.vue")

//核销卡管理
const hxList = () => import("@/views/Network/HxList/IndexView.vue")
const hxSet = () => import("@/views/Network/HxList/AddView.vue")
const hxEdit = () => import("@/views/Network/HxList/EditView.vue")

//会员管理
const Vip = () => import("@/views/Network/VipList/IndexView.vue")
const VipDetails = () => import("@/views/Network/VipList/DetailsView.vue")

//账号管理
const UserList = () => import("@/views/User/IndexView.vue")
const UserAdd = () => import("@/views/User/AddView.vue")
const UserEdit = () => import("@/views/User/EditView.vue")
const SetPower = () => import("@/views/User/SetPowerView.vue")

//短信管理
const MessageList = () => import("@/views/Network/Message/IndexView.vue")
const MessageInfo = () => import("@/views/Network/Message/DetailsView.vue")
const MessageBuy = () => import("@/views/Network/Message/BuyView.vue")
const MessagePackage = () =>import("@/views/Network/Message/PackageView.vue")
const AddPackage = () =>import("@/views/Network/Message/AddPackView.vue")
const EditPackage = () =>import("@/views/Network/Message/EditPackView.vue")

//其他管理
const UserSet = () => import("@/views/Other/UserView.vue")
const Notice = () => import("@/views/Other/NoticeView.vue")
const NoticeAdd = () => import("@/views/Other/NoticeAddView.vue")
const NoticeEdit = () => import("@/views/Other/NoticeEditView.vue")
const MenuList = () => import("@/views/Other/MenuListView.vue")
const MenuAdd = () => import("@/views/Other/MenuAddView.vue")
const MenuEdit = () => import("@/views/Other/MenuEditView.vue")

//财务管理
const Order = () => import("@/views/Order/IndexView.vue")
const PayList = () => import("@/views/Order/PayList/IndexView.vue")
const PayDetails = () => import("@/views/Order/OrderList/PayDetailsView.vue")
const UseList = () => import("@/views/Order/UseList/IndexView.vue")
const UseDetails = () => import("@/views/Order/UseList/DetailsView.vue")
const OrderList = () => import("@/views/Order/OrderList/IndexView.vue")
const SetInList = () => import("@/views/Order/OrderList/SetInView.vue")
const SetOutList = () => import("@/views/Order/OrderList/SetOutView.vue")

//数据管理
const DataForm = () => import("@/views/DataForm/IndexView.vue")
const NetworkData = () => import("@/views/DataForm/Network/IndexView.vue")
const CrossStoreData = () => import("@/views/DataForm/CrossStore/IndexView.vue")
const MarketData = () => import("@/views/DataForm/Market/IndexView.vue")
const UserData = () => import("@/views/DataForm/User/IndexView.vue")
const VipData = () => import("@/views/DataForm/Vip/IndexView.vue")

//设备状态
const DeviceRun = () =>import("@/views/Device/RunView.vue")
const DeviceDetailsRun = () =>import("@/views/Device/RunDetailsView.vue")
const DeviceNoRun = () =>import("@/views/Device/NoRunView.vue")
const DeviceNotOnLine = () =>import("@/views/Device/NotOnLineView.vue")
Vue.use(VueRouter);

const routes = [{
		path: '/',
		component: ()=>import('../components/Manage.vue'),
		redirect:'/home',
		children: [{
				path: '/home',
				name: 'home',
				component: Home
			}, {
				path: '/userlist',
				name: 'userlist',
				component: UserList
			}, {
				path: '/useradd',
				name: 'useradd',
				component: UserAdd
			}, {
				path: '/useredit',
				name: 'useredit',
				component: UserEdit
			}, {
				path: '/setpower',
				name: 'setpower',
				component: SetPower
			},
			{
				path: '/userset',
				name: 'userset',
				component: UserSet
			},
			{
				path: '/rundevice',
				name: 'rundevice',
				component: DeviceRun
			},
			{
				path: '/rundevicedetails',
				name: 'rundevicedetails',
				component: DeviceDetailsRun
			},
			{
				path: '/norundevice',
				name: 'norundevice',
				component: DeviceNoRun
			},
			{
				path: '/noonlinedevice',
				name: 'noonlinedevice',
				component: DeviceNotOnLine
			},
			{
				path: '/notice',
				name: 'notice',
				component: Notice
			},
			{
				path: '/noticeedit',
				name: 'noticeedit',
				component: NoticeEdit
			}, {
				path: '/noticeadd',
				name: 'noticeadd',
				component: NoticeAdd
			}, {
				path: 'menulist',
				name: 'menulist',
				component: MenuList
			}, {
				path: 'menuadd',
				name: 'menuadd',
				component: MenuAdd
			}, {
				path: 'menuedit',
				name: 'menuedit',
				component: MenuEdit
			},
			{
				path: '/network',
				name: 'network',
				component: Network,
				children: [{
						path: 'networklist',
						name: 'networklist',
						component: NetworkList
					},
					{
						path: 'networkdetails',
						name: 'networkdetails',
						component: NetworkDetails
					},
					{
						path: 'networkadd',
						name: 'networkadd',
						component: NetworkAdd
					},
					{
						path: 'networkedit',
						name: 'networkedit',
						component: NetworkEdit
					},
					{
						path: 'setpay',
						name: 'setpay',
						component: SetPay
					},
					{
						path: 'getpay',
						name: 'getpay',
						component: GetPay
					},
					{
						path: 'devicelist',
						name: 'devicelist',
						component: DeviceList
					},
					{
						path: 'deviceadd',
						name: 'deviceadd',
						component: DeviceAdd
					}, {
						path: 'devicedetails',
						name: 'devicedetails',
						component: DeviceDetails
					}, {
						path: 'deviceedit',
						name: 'deviceedit',
						component: DeviceEdit
					}, {
						path: 'devicesetpay',
						name: 'devicesetpay',
						component: DeviceSetPay
					},
					{
						path: 'vip',
						name: 'vip',
						component: Vip
					},
					{
						path: 'vipdetails',
						name: 'vipdetails',
						component: VipDetails
					},
					{
						path: 'card',
						name: 'card',
						component: Card
					},
					{
						path: 'cardadd',
						name: 'cardadd',
						component: CardAdd
					},
					{
						path: 'cardedit',
						name: 'cardedit',
						component: CardEdit
					}, {
						path: 'hxadd',
						name: 'hxadd',
						component: HxAdd
					},{
						path:'hxcardedit',
						name:'hxcardedit',
						component:HxCardEdit
					},
					{
						path: 'coupon',
						name: 'coupon',
						component: Coupon
					},
					{
						path: 'couponadd',
						name: 'couponadd',
						component: CouponAdd
					},
					{
						path: 'couponedit',
						name: 'couponedit',
						component: CouponEdit
					},
					{
						path: 'exchange',
						name: 'exchange',
						component: ExChange
					},
					{
						path: 'exchangelist',
						name: 'exchangelist',
						component: ExChangeList
					},
					{
						path: 'networkorder',
						name: 'networkorder',
						component: NetworkOrder
					},
					{
						path: 'vip',
						name: 'vip',
						component: Vip
					},{
						path:'networkorderdetails',
						name: 'networkorderdetails',
						component: NetworkOrderDetails
					},{
						path:'hxlist',
						name: 'hxlist',
						component: hxList
					},{
						path:'hxset',
						name: 'hxadd',
						component: hxSet
					},{
						path:'hxedit',
						name: 'hxedit',
						component: hxEdit
					},{
						path:'messagelist',
						name: 'messagelist',
						component: MessageList
					},{
						path:'messageinfo',
						name: 'messageinfo',
						component: MessageInfo
					},{
						path:'messagebuy',
						name: 'messagebuy',
						component: MessageBuy
					},{
						path:'messagepackage',
						name: 'messagepackage',
						component: MessagePackage
					},{
						path:'addpackage',
						name: 'addpackage',
						component: AddPackage
					},{
						path:'editpackage',
						name: 'editpackage',
						component: EditPackage
					}
				]
			},
			{
				path: '/order',
				name: 'order',
				component: Order,
				children: [{
						path: 'orderlist',
						name: 'orderlist',
						component: OrderList
					}, {
						path: 'setinlist',
						name: 'setinlist',
						component: SetInList
					},{
						path: 'setoutlist',
						name: 'setoutlist',
						component: SetOutList
					},{
						path: 'paylist',
						name: 'paylist',
						component: PayList
					},
					{
						path: 'paydetails',
						name: 'paydetails',
						component: PayDetails
					},
					{
						path: 'uselist',
						name: 'uselist',
						component: UseList
					},
					{
						path: 'usedetails',
						name: 'usedetails',
						component: UseDetails
					},
				]
			},
			{
				path: '/dataForm',
				name: 'dataform',
				component: DataForm,
				children: [{
						path: 'networkData',
						name: 'networkData',
						component: NetworkData
					},
					{
						path: 'crossStoreData',
						name: 'crossStoreData',
						component: CrossStoreData
					},
					{
						path: 'marketData',
						name: 'marketData',
						component: MarketData
					},
					{
						path: 'userData',
						name: 'userData',
						component: UserData
					},
					{
						path: 'vipData',
						name: 'vipData',
						component: VipData
					}
				]
			}

		],
	},
	{
		path: '/login',
		name: 'login',
		component: Login,
	}
];

// const routes = [{
// 		path: '/',
// 		component: ()=>import('../components/Manage.vue'),
//         redirect:"/user",
//         children:[
//             {
//                 path: '/user',
//                 name: 'user',
//                 component: UseList,
//             }
//         ]
// 	},
// ];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

export default router;